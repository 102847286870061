// export const HOST = 'https://deep.envirowasteadmin.com.au/' //OLD
// export const HOST_WITHOUT_SLASH = 'https://deep.envirowasteadmin.com.au' //OLD


 export const HOST = 'https://production-deep.envirowasteadmin.com.au/'
// export const HOST_WITHOUT_SLASH = ''

//export const HOST = 'https://staging-deep.envirowasteadmin.com.au/'
export const HOST_WITHOUT_SLASH = ''
    const API_VERSION = 'api/v1/'
    const BASE = HOST + API_VERSION

export const QUOTE_INFOS = BASE + 'sales/client/quote/' //append uuid

export const QUOTE_ACCEPT = BASE + 'sales/client/status/accepted/' //append uuid && POST DATA => template (quote main template html)
export const QUOTE_REJECT = BASE + 'sales/client/status/rejected/' //append uuid
export const QUOTE_ATTACH_FILE = BASE + 'sales/quote/client/response/files/'  //append uuid && POST DATA => template_content (html) || template_id (id)
